/*body {
   overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
  background: url('./assets/images/main.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed; 
}*/
.App {
  overflow-y: auto;
  height: 100vh;
  overflow-x: hidden;
  background: url('./assets/images/main.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

/* Loading */
.loading {
  background-color: #00000080;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
}

.selectedname {
  font-size: 12px;
  color: #960685;
}
.slot-count {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ScrollBar */

/* width */
::-webkit-scrollbar {
  width: 12px;
}
.name {
  color: #960685;
  font-weight: bold;
  text-transform: uppercase;
}
.alert-primary {
  background-color: #efa6e6 !important;
  color: #0a0a0a !important;
}
/* Modal */
.modal-dialog.modal-lg {
  max-width: 650px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}
/* End Scrollbar */

/* Order Card */
.order {
  width: 100%;
  padding-bottom: 10px;
  min-height: 280px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 20px;
  box-shadow: 3px 6px 10px 0px rgba(0, 0, 0, 0.269);
  background-repeat: no-repeat;
  background-image: url('./assets/images/card.png');
  /* background-image: url('./assets/images/alovera.png'); */
  background-size: 170px 108px;
  background-position: -1% 102%;
  /* background-size: 150px 150px;
  background-position: -3% 120%; */
}
/* Product Card */
.product {
  width: 100%;
  padding-bottom: 10px;
  min-height: 280px;
  border-radius: 10px;
  background-color: #ffffff;
  margin-bottom: 20px;
  box-shadow: 3px 6px 10px 0px rgba(0, 0, 0, 0.269);
  background-repeat: no-repeat;
  background-image: url('./assets/images/spa-products.png');
  background-size: 155px 140px;
  background-position: 2% 97%;
  position: relative;
}
.product-container {
  max-height: 280px !important;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px !important;
}
img.item-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
/* .buttonsClass {
  position: absolute;
  bottom: 25px;
  right: 10px;
} */
/* .rowing {
  overflow-y: auto;
} */
.column {
  overflow-y: auto;
  height: inherit;
  text-align: left;
}
.heading-col {
  background-color: black;
  padding: 5px;
  text-align: center;
  margin: auto;
  border-radius: 5px;
  width: 200px;
  color: #ffffff;
}
.order-total {
  font-size: 20px;
  padding-top: 15px;
  padding-right: 15px;
  font-weight: bold;
  text-align: right;
}
.order-id {
  font-size: 20px;
  padding-top: 15px;
  padding-right: 15px;
  font-weight: bold;
  text-align: left;
}
.inner_btn > button.order-btn {
  font-size: 12px;
  background-color: black;
  /* border: 1px solid black; */
  float: right;
  color: white;
  /* box-shadow: 0 0 0 0.12rem rgb(0 0 0 / 30%); */
}
.inner_btn > button.order-btn:hover {
  color: #960685;
  background-color: white;
  box-shadow: 0 0 0 0.12rem rgb(0 0 0 / 30%);
}

.date-class {
  font-size: 15px;
  color: rgb(60, 59, 59);
  font-weight: bold;
}
.icon-class {
  width: 20px;
  height: 20px;
  margin: 0px 5px 2px 0px;
}
.modal-header-dark {
  background-color: #f6f6f6;
}
.modal-header-title {
  font-size: 20px;
}
.modal-content {
  width: 100%;
  margin: auto;
}
/* Sawal BackGrround */
.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  background-position: center bottom !important;
}
/* Swal Title */
.swal2-title {
  color: black !important;
}
.font-weight-bold {
  font-weight: bold;
}
.heading-line {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.float-right {
  float: right !important;
}
/* Icon Design */
a.icon-append {
  padding: 0px;
  background: none;
  border: none;
  font-size: 22px;
  display: block;
  color: black;
  margin: -10px auto 0px;
}
a.icon-append:hover {
  padding: 0px;
  background: none;
  border: none;
  font-size: 22px;
  display: block;
  color: black;
  margin: -10px auto 0px;
  cursor: pointer;
}

/* Receipt Design */
p.vat {
  font-size: 14px;
  font-weight: bold;
}
.receipt-amount-table {
  border: 1px solid rgb(213, 213, 213);
}
.receipt-amount-table td {
  border: none !important;
}
.edit-btn {
  color: cornflowerblue;
  cursor: pointer;
  font-size: 18px;
}
.badge {
  cursor: pointer;
}
/* Navigation */
.menu {
  background-color: white;
  height: auto;
  padding-top: 15px;
  border-radius: 5px;
  box-shadow: 3px 6px 10px 0px rgba(0, 0, 0, 0.269);
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
a.nav-link {
  border-radius: 50%;
  background: black;
  color: #f1f1f1;
  height: 85px;
  width: 85px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px solid #fff;
  text-align: center;
  box-shadow: 3px 6px 10px 0px rgba(0, 0, 0, 0.269);
}
/* Sign In Container */
.signin-container {
  background-color: rgba(237, 237, 237, 0.125);
  min-height: 480px;
  border-radius: 20px;
}
.signin-inner-container {
  display: flex;
  align-content: center;
}
.auth-bg {
  height: 100vh;
  background-image: url('./assets/images/signin-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center center; */
}
.logo-conatainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
img.logo {
  width: 350px;
  height: 100px;
}
.purple {
  color: #960685;
}
.timetable-header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.time-col {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Payment Status Css */
span.confirmBtn {
  margin-left: 10px;
}
.success_icon{
  color: green;
}
.pending_icon{
  color: rgb(255, 207, 32)
}
.reject_icon{
  color: #f14115;
}
.close-btn {
  float: right;
  position: relative;
  top: 10px;
}
.closed-tag {
  background-color: #960685;
  color: #fff;
  padding: 5px;
  border-top-left-radius: 10px;
  position: relative;
  top: 5px;
  left: -16px;
}
.payment_reason_div {
  color: white;
  background: #f14115;
  width: auto;
  position: relative;
  z-index: 10;
  padding: 10px;
  top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}